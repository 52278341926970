import axios from 'axios';
import { useEffect, useState } from 'react';
import Table from '../Table/Table';
import Spinner from '../Spinner/Spinner';
import './Search.css'

function Search() {
    const backUrl = process.env.REACT_APP_BACK_URL;

    const [data, setData] = useState([]);
    const [dataDogs, setDataDogs] = useState([]);
    const [dataCats, setDataCats] = useState([]);
    const [dataRandomDogs, setDataRandomDogs] = useState([]);
    const [dataRandomCats, setDataRandomCats] = useState([]);
    const [dataRegionBreed, setDataRegionBreed] = useState([]);
    
    const [searchName, setSearchName] = useState('');
    const [searchInzh, setSearchInzh] = useState('');
    const [notFoundName, setNotFoundName] = useState(false);
    const [notFoundInzh, setNotFoundInzh] = useState(false);
    
    const [helpOpen, setHelpOpen] = useState(false);
    const [isSearching, setIsSearching] = useState(false);

    const [showDogsTable, setShowDogsTable] = useState(true);
    const [showCatsTable, setShowCatsTable] = useState(true);
    const [showRandomDogsTable, setShowRandomDogsTable] = useState(true);
    const [showRandomCatsTable, setShowRandomCatsTable] = useState(true);

    const [loadingDogs, setLoadingDogs] = useState(false);
    const [loadingCats, setLoadingCats] = useState(false);
    const [loadingRandomDogs, setLoadingRandomDogs] = useState(false);
    const [loadingRandomCats, setLoadingRandomCats] = useState(false);
    const [loadingSearch, setLoadingSearch] = useState(false);

    const [exactMatch, setExactMatch] = useState(false);
    const [activeTab, setActiveTab] = useState(0);
    
    
    const changeSearchName = (event) => {
        const value = event.target.value; 
        setSearchName(value);
    };
    const changeSearchInzh = (event) => {
        const value = event.target.value; 
        setSearchInzh(value);
    };

    const handleHelpOpen = () => {
        setHelpOpen(!helpOpen);
    };
        
    
    const handleTabClick = (index) => {
        setData([]);
        setSearchName('');
        setSearchInzh('');
        setActiveTab(index);
    };

    const handleSearch = async () => {
        try {
            // Выполняем запрос к API с использованием значения searchName          
            if (searchName != "")
            {
                setIsSearching(true);
                setLoadingSearch(true);
                if (exactMatch)
                    {
                        const response = await axios.get(`${backUrl}/api/v1/pets/pet/statistics/search_by_names?name=${searchName}&contains=true`);
                        setData(response.data);
                        setLoadingSearch(false);
                    }
                else
                {
                    const response = await axios.get(`${backUrl}/api/v1/pets/pet/statistics/search_by_names?name=${searchName}`);
                    setData(response.data);
                    setLoadingSearch(false);
                }
                
            }
            else
            {
                console.log("else");
                setIsSearching(false);
                setLoadingSearch(false);
                setData([]);
            }
            
        } catch (error) {
            setIsSearching(false);
            console.error('Error fetching data:', error);
        }
    };

    const handleSearchInzh = async () => {
        setNotFoundInzh(false)
        try {
            // Выполняем запрос к API с использованием значения searchInzh
            if (searchInzh != "")
            {
                if (!/^\d{10,15}$/.test(searchInzh)) {
                    alert('ИНЖ должен содержать от 10 до 15 цифр');
                    return;
                }
                setIsSearching(true);
                setLoadingSearch(true);                
                const response = await axios.get(`${backUrl}/api/v1/pets/pet/statistics/search_by_inzh?inzh=${searchInzh}`);
                setData(response.data);
                setLoadingSearch(false);
                setNotFoundInzh(response.data.length==0)
                
            }
            else
            {
                console.log("else");
                setIsSearching(false);
                setLoadingSearch(false);
                setData([]);
            }
            
        } catch (error) {
            setIsSearching(false);
            console.error('Error fetching data:', error);
        }
    };

    const handleKeyDown = (event) => {
    if (event.key === 'Enter') {          
        handleSearch();
    }
    };
    const handleKeyDownInzh = (event) => {
    if (event.key === 'Enter') {          
        handleSearchInzh();
    }
    };

    return (
        <div style={{backgroundColor: "#cae0de"}}>
            <div className="w3-top">
            <div className="w3-bar w3-card" style={{backgroundColor: "#015367"}}>
                <a href="/search" className="w3-bar-item w3-button w3-padding-large" style={{color:"#fff"}}>Поиск</a>
                <a href="/names_report" className="w3-bar-item w3-button w3-padding-large" style={{color:"#fff"}}>Топ кличек</a>
                <a href="/breeds_report" className="w3-bar-item w3-button w3-padding-large" style={{color:"#fff"}}>Топ пород</a>
                <a href="/articles_list" className="w3-bar-item w3-button w3-padding-large" style={{color:"#fff"}}>Полезное</a>
                
            </div>
            </div>            
            <div className="w3-content" style={{maxWidth:"2000px",marginTop:"46px"}}>
                <div className="w3-container w3-content w3-center w3-padding-64" style={{maxWidth:"800px"}} id="band">                
                    
                    <div className="w3-container w3-content w3-center" style={{maxWidth:"800px",paddingLeft:0,paddingRight:0}}>
                        <img className="w3-image" src="cats_dogs_3.jpeg" alt="Картинка" /><br></br><br></br>
                    </div>

                    <div className="custom-tabs">

                        <div>
                            <button 
                            onClick={() => handleTabClick(0)}
                            className={activeTab === 0 ? 'active' : ''}
                            >Поиск по кличке</button>
                            <button
                            onClick={() => handleTabClick(1)}
                            className={activeTab === 1 ? 'active' : ''}
                            >Поиск по ИНЖ</button>
                        </div>

                        <div className="custom-tabs-content">

                            {activeTab === 0 && 
                            <div className="w3-container w3-content w3-center w3-padding-8" style={{ maxWidth: "800px", textAlign: "center" }}>
                                <div style={{ display: "flex", alignItems: "center" }}>
                                    <input className="w3-input w3-border" type="text" placeholder="Введите кличку" required name="Message" value={searchName} onChange={changeSearchName} style={{ width: '70%' }} onKeyDown={handleKeyDown} />
                                    <button
                                    className="w3-button w3-blue"
                                    onClick={handleSearch}
                                    >
                                    Найти
                                    </button>
                                </div>                        
                                <div style={{ whiteSpace: "nowrap", display: "hidden", textAlign:"left" }}>
                                    <label>
                                    <input type="checkbox" checked={exactMatch} onChange={() => setExactMatch(!exactMatch)}/>Неточный поиск</label>
                                    <br></br>
                                    <br></br>
                                </div>
                            </div>
                            }
                            {activeTab === 1 && 
                            <div className="w3-container w3-content w3-center w3-padding-8" style={{ maxWidth: "800px", textAlign: "center" }}>
                                <div style={{ display: "flex", alignItems: "center" }}>
                                    <input 
                                        className="w3-input w3-border" 
                                        type="text" 
                                        placeholder="Введите ИНЖ" 
                                        required name="Message" 
                                        value={searchInzh} 
                                        onChange={changeSearchInzh} 
                                        style={{ width: '70%' }} 
                                        onKeyDown={handleKeyDownInzh} />
                                    <button
                                    className="w3-button w3-blue"
                                    onClick={handleSearchInzh}
                                    disabled={searchInzh.length < 10}
                                    >
                                    Найти
                                    </button>
                                </div>
                            </div>
                            }
                        </div>
                    </div>

                {data.length == 0 ?                 
                    <div></div>:
                    <div>
                        {!loadingSearch ? (
                            activeTab === 0 ? <Table data={data} /> : <Table data={data} by_inzh="true" />
                        ) : (
                            <Spinner />
                        )}
                    </div>
                }
                {notFoundInzh?
                <div className="w3-container w3-content w3-center w3-padding-16" style={{border:"1px dotted #fff"}} >
                    <p>К сожалению, ничего не найдено</p>
                </div>:<div></div>}

                    <hr></hr>

                    <a href="#" onClick={handleHelpOpen}>Чем полезен этот сервис?</a>
                    {helpOpen?
                    <div className='help-text'>
                        <p>1. Можно поискать животных, с тем же именем, что и у вас. Поверьте, такие вполне могут быть.</p>
                        <p>2. Можно удостовериться, что вы не оригинальны в подборе клички своего питомца. Например, кличку своего пса мы обнаружили у 22 собак... и 16 кошек</p>
                        <p>3. В конце концов, можно увидеть, насколько богата фантазия у казахстанцев, судя по отчетам на <a href="/names_report">этой</a> и <a href="/breeds_report">этой</a> страницах</p>
                        <p>4. Если вы нашли животное на улице и просканировали номер чипа, можно узнать, в каком учреждении их чипировали. В этом учреждении вам помогут найти владельца</p>

                    </div>:<div className=''></div>}

                    <div>
                        <p className="w3-container">Данные взяты из публичного реестра домашних животных Казахстана <a target="_blank" href="https://tanba.kezekte.kz/ru/reestr-tanba-public/animal/list">tanba.kezekte.kz</a></p>
                    </div>

                </div>
            </div>
            </div>
    );
}

export default Search;
