import axios from 'axios';
import { useEffect, useState } from 'react';
import Table from '../Table/Table';
import Spinner from '../Spinner/Spinner';
import './NamesReport.css'

function NamesReport() {
    const backUrl = process.env.REACT_APP_BACK_URL;
    const [data, setData] = useState([]);
    const [dataDogs, setDataDogs] = useState([]);
    const [dataCats, setDataCats] = useState([]);
    const [dataRandomDogs, setDataRandomDogs] = useState([]);
    const [dataRandomCats, setDataRandomCats] = useState([]);
    
    const [showDogsTable, setShowDogsTable] = useState(true);
    const [showCatsTable, setShowCatsTable] = useState(true);
    const [showRandomDogsTable, setShowRandomDogsTable] = useState(true);
    const [showRandomCatsTable, setShowRandomCatsTable] = useState(true);

    const [loadingDogs, setLoadingDogs] = useState(false);
    const [loadingCats, setLoadingCats] = useState(false);
    const [loadingRandomDogs, setLoadingRandomDogs] = useState(false);
    const [loadingRandomCats, setLoadingRandomCats] = useState(false);


    const toggleDogsTable = () => {
        setShowDogsTable(!showDogsTable);
      };
    const toggleCatsTable = () => {
        setShowCatsTable(!showCatsTable);
    };
    const toggleRandomDogsTable = () => {
      setShowRandomDogsTable(!showRandomDogsTable);
    };
    const toggleRandomCatsTable = () => {
        setShowRandomCatsTable(!showRandomCatsTable);
    };
   

    useEffect(() => {
        const fetchData = async () => {
          try {
            // Выполняем запросы к API
            setLoadingDogs(true);
            setLoadingCats(true);
            setLoadingRandomDogs(true);
            setLoadingRandomCats(true);
            
            const dogsResponse = await axios.get(`${backUrl}/api/v1/pets/pet/statistics/by_names_common_dog`);
            const catsResponse = await axios.get(`${backUrl}/api/v1/pets/pet/statistics/by_names_common_cat`);
            const randomDogsResponse = await axios.get(`${backUrl}/api/v1/pets/pet/statistics/by_names_unique_dog`);
            const randomCatsResponse = await axios.get(`${backUrl}/api/v1/pets/pet/statistics/by_names_unique_cat`);
            
            setDataDogs(dogsResponse.data);
            setDataCats(catsResponse.data);
            setDataRandomDogs(randomDogsResponse.data);
            setDataRandomCats(randomCatsResponse.data);
          } catch (error) {
            console.error('Error fetching data:', error);
          } finally {
            setLoadingDogs(false);
            setLoadingCats(false);
            setLoadingRandomDogs(false);
            setLoadingRandomCats(false);
          }
        };
        fetchData();
      }, []);  

    return (
        <div style={{backgroundColor: "#cae0de"}}>
            <div className="w3-top">
                <div className="w3-bar w3-card" style={{backgroundColor: "#015367"}}>
                    <a href="/search" className="w3-bar-item w3-button w3-padding-large" style={{color:"#fff"}}>Поиск</a>
                    <a href="/names_report" className="w3-bar-item w3-button w3-padding-large" style={{color:"#fff"}}>Топ кличек</a>
                    <a href="/breeds_report" className="w3-bar-item w3-button w3-padding-large" style={{color:"#fff"}}>Топ пород</a>                
                    <a href="/articles_list" className="w3-bar-item w3-button w3-padding-large" style={{color:"#fff"}}>Полезное</a>
                </div>
            </div>            
            <div className="w3-content" style={{maxWidth:"2000px",marginTop:"46px"}}>
                <div className="w3-container w3-content w3-center w3-padding-64" style={{maxWidth:"800px"}} id="band">                

                <div className="w3-row w3-padding-8" style={{backgroundColor:"#fff", padding:'12px'}}>
                    <h4 onClick={toggleDogsTable} style={{ cursor: 'pointer' }}>Самые популярные клички собак в Казахстане</h4>
                    {showDogsTable && loadingDogs && <Spinner />}
                    {showDogsTable && !loadingDogs && <Table data={dataDogs} />}
                    <hr></hr>
                    <h4 onClick={toggleCatsTable} style={{ cursor: 'pointer' }}>Самые популярные клички кошек в Казахстане</h4>
                    {showCatsTable && loadingCats && <Spinner />}
                    {showCatsTable && !loadingCats && <Table data={dataCats} />}

                    <h4 onClick={toggleRandomDogsTable} style={{ cursor: 'pointer' }}>Рандомные уникальные клички собак</h4>
                    {showRandomDogsTable && loadingRandomDogs && <Spinner />}
                    {showRandomDogsTable && !loadingRandomDogs && <Table data={dataRandomDogs} />}
                    <hr></hr>
                    <h4 onClick={toggleRandomCatsTable} style={{ cursor: 'pointer' }}>Рандомные уникальные клички кошек</h4>
                    {showRandomCatsTable && loadingRandomCats && <Spinner />}
                    {showRandomCatsTable && !loadingRandomCats && <Table data={dataRandomCats} />}

                </div>
                <hr></hr>
                <div>
                    <p className="w3-container">Данные взяты из публичного реестра домашних животных Казахстана <a target="_blank" href="https://tanba.kezekte.kz/ru/reestr-tanba-public/animal/list">tanba.kezekte.kz</a></p>
                    
                </div>
            </div>
            </div>
        </div>
    );
}

export default NamesReport;
