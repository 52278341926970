import axios from 'axios';
import { useEffect, useState } from 'react';
import Spinner from '../Spinner/Spinner';

function ArticleList() {
    const backUrl = process.env.REACT_APP_BACK_URL;
    const [loadingArticles, setLoadingArticles] = useState(false);
    const [dataArticles, setDataArticles] = useState({});

    useEffect(() => {
        const fetchData = async () => {
          try {            
            setLoadingArticles(true);    
            const articlesResponse = await axios.get(`${backUrl}/api/v1/article/article`);    
            setDataArticles(articlesResponse.data);
          } catch (error) {
            console.error('Error fetching data:', error);
          } finally {
            setLoadingArticles(false);
          }
        };
        fetchData();
      }, []);

    
      return (
        <div style={{backgroundColor: "#cae0de"}}>
            <div className="w3-top">
            <div className="w3-bar w3-card" style={{backgroundColor: "#015367"}}>
                <a href="/search" className="w3-bar-item w3-button w3-padding-large" style={{color:"#fff"}}>Поиск</a>
                <a href="/names_report" className="w3-bar-item w3-button w3-padding-large" style={{color:"#fff"}}>Топ кличек</a>
                <a href="/breeds_report" className="w3-bar-item w3-button w3-padding-large" style={{color:"#fff"}}>Топ пород</a>
                <a href="/articles_list" className="w3-bar-item w3-button w3-padding-large" style={{color:"#fff"}}>Полезное</a>
            </div>
            </div>            
            <div className="w3-content" style={{maxWidth:"2000px",marginTop:"46px"}}>
                <div className="w3-container w3-content w3-center w3-padding-16" style={{maxWidth:"800px"}} id="band">                
                    <div className="w3-row w3-padding-8" style={{backgroundColor:"#fff", padding:'12px'}}>
                        <h4>Полезное</h4>
                        <hr></hr>
                        {loadingArticles ? <Spinner />:<div>
                        {dataArticles && dataArticles.length > 0 ? (
                            dataArticles.map((item, index) => (
                                <div key={item.id}>
                                    <h5
                                        style={{
                                            textAlign: 'left',                                
                                            backgroundColor: '#eee',
                                        }}
                                    >
                                        {item.title_ru}
                                    </h5>
                                    <div style={{textAlign:'left'}}>{item.preview_content_ru}</div><br />
                                    <a href={`article_detail/${item.id}`}>Читать полностью</a>

                                </div>
                            ))
                        ) : (
                            <p>Пока что здесь нет статей. Если хотите стать соавтором, можете написать в <strong><a href="https://t.me/mussinalibek">телегу</a></strong></p>
                        )}
                        </div>}
                    </div>
                    <div>
                                        
                    
                </div>
                </div>
            </div>
            </div>
    );
}
export default ArticleList;
