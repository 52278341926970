import { BrowserRouter } from 'react-router-dom';
import App from './App';
import { createRoot } from 'react-dom/client';
const rootElement = document.getElementById('root');


createRoot(rootElement).render(
  <BrowserRouter>
  <App />
  </BrowserRouter>
);