import React from 'react';
import './ImageModal.css';

const ImageModal = ({ imageUrl, onClose }) => {    
    console.log('wwwww')
  return (
    <div className="modal">
      <div className="modal-content">
        <span className="close" onClick={onClose}>&times;</span>
        <img class="modal-image" src={imageUrl} alt="Full Size" />
      </div>
    </div>
  );
};

export default ImageModal;