import axios from 'axios';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Spinner from '../Spinner/Spinner';
import DOMPurify from 'dompurify';


function ArticleDetail() {
    const backUrl = process.env.REACT_APP_BACK_URL;
    const [loadingArticles, setLoadingArticles] = useState(false);
    const [dataArticle, setDataArticle] = useState({});
    const { id } = useParams();

    useEffect(() => {
        const fetchData = async () => {
          try {            
            setLoadingArticles(true);    
            const articlesResponse = await axios.get(`${backUrl}/api/v1/article/article/${id}`);    
            setDataArticle(articlesResponse.data);
          } catch (error) {
            console.error('Error fetching data:', error);
          } finally {
            setLoadingArticles(false);
          }
        };
        fetchData();
      }, []);

    
      return (
        <div style={{backgroundColor: "#cae0de"}}>
            <div className="w3-top">
            <div className="w3-bar w3-card" style={{backgroundColor: "#015367"}}>
                <a href="/search" className="w3-bar-item w3-button w3-padding-large" style={{color:"#fff"}}>Поиск</a>
                <a href="/names_report" className="w3-bar-item w3-button w3-padding-large" style={{color:"#fff"}}>Топ кличек</a>
                <a href="/breeds_report" className="w3-bar-item w3-button w3-padding-large" style={{color:"#fff"}}>Топ пород</a>
                <a href="/articles_list" className="w3-bar-item w3-button w3-padding-large" style={{color:"#fff"}}>Полезное</a>
            </div>
            </div>            
            <div className="w3-content" style={{maxWidth:"2000px",marginTop:"46px"}}>
                <div className="w3-container w3-content w3-center w3-padding-16" style={{maxWidth:"800px"}} id="band">                
                    <div className="w3-row w3-padding-8" style={{backgroundColor:"#fff", padding:'12px'}}>
                        <h1>{dataArticle.title_ru}</h1>
                        <hr></hr>
                        {loadingArticles ? <Spinner />:<div>
                        {dataArticle ? (                            
                                <div key={dataArticle.id} style={{textAlign:'left'}}>
                                    <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(dataArticle.content_ru) }} />
                                    <hr></hr>
                                    {new Date(dataArticle.created_at).toLocaleString()}
                                </div>                            
                        ) : (
                            <p>No articles available</p>
                        )}
                        </div>}
                    </div>
                    <div>
                    
                    
                    
                </div>
                </div>
            </div>
            </div>
    );
}
export default ArticleDetail;
