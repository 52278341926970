import React, { useEffect, useState } from 'react';
import './Table.css';
import ImageModal from '../ImageModal/ImageModal';

const Table = ({ data, by_breeds, by_inzh }) => {    
  const [modalOpen, setModalOpen] = useState(false);
  const openModal = (imageUrl) => {          
    setSelectedImage(imageUrl);
    setModalOpen(true);
  };

  const closeModal = () => {
      setModalOpen(false);
  };
  const [selectedImage, setSelectedImage] = useState(null);
  const backUrl = process.env.REACT_APP_BACK_URL;

    if (by_breeds == 'true')
    {
        
        let oldRegion = "";

        return (
            <div>
                <table className="styled-table">
                    <thead>
                        <tr>
                          <th></th>
                          <th>Порода</th>
                          <th>Количество</th>                        
                        </tr>
                    </thead>
                    <tbody>
                    {data.map((item, index) => {
                         if (oldRegion !== item.region_name) {
                            oldRegion = item.region_name;
                            return (
                              <tr key={`region-${index}`}>
                                <td colSpan={3} style={{textAlign:"center"}}>
                                  <strong>{item.region_name}</strong>
                                </td>
                              </tr>
                            );
                          }
                        return (
                            <tr key={`row-${index}`}>                            
                                <td>
                                {item.image && 
                                  <img
                                    style={{ width: "25px", borderRadius: "25px", cursor: "pointer" }}
                                    src={backUrl + '/media/' + item.image}
                                    onClick={() => openModal(backUrl + '/media/' + item.image)}
                                  />
                                }
                                  </td>
                                <td>{item.breed}</td>
                                <td>{item.count}</td>                            
                            </tr>
                        );
                        
                        })}
                    </tbody>
                </table>
                {modalOpen && 
                  <ImageModal imageUrl={selectedImage} onClose={closeModal} />
                }
            </div>
        );
    }
    if (by_inzh == 'true')
    {      
        return (
            <div>
                <table className="styled-table">
                    <thead>
                        <tr>
                          <th style={{width:'50%'}}>Кличка<br></br>ИНЖ, Место мечения</th>                          
                        </tr>
                    </thead>
                    <tbody>
                    {data.map((item, index) => {
                      
                      return (
                            <tr key={`row-${index}`}>                            
                                <td> 
                                  <table className='inner-table'>
                                    <tr>
                                      <td rowSpan="2">
                                        {item.animal_type == 'Собака' ? 
                                        
                                        <div>
                                            {item?.breed_obj.image ? 
                                              <img
                                                style={{ width: "25px", borderRadius: "25px", cursor: "pointer" }}
                                                src={backUrl + item?.breed_obj.image}
                                                onClick={() => openModal(backUrl + item?.breed_obj.image)}
                                              />:<img alt="Собака" className="pet-icon" src="dog_icon.jpeg"/>
                                            }                                            
                                        </div>
                                        :<div></div>}
                                        {item.animal_type == 'Кошка' ? 
                                        <img alt="Кошка" className="pet-icon" src="cat_icon.jpeg"/>:<div></div>}
                                      </td>
                                      <td>{item.pet_name.toUpperCase()}<br></br>{item.pet_number.replace('/', '/ ')}</td>
                                    </tr>                                    
                                  </table>
                                  <hr style={{margin:"2px"}}></hr>
                                {item.region?.name_ru}
                                    <br></br>
                                    <strong>{item.register_place?.name_ru}</strong>
                                    <br></br>
                                    {item.register_place?.fact_address}
                                    <br></br><br></br>
                                    {item.register_place?.email}<br></br>
                                    <strong><a href={`tel:${item.register_place?.phone}`}>{item.register_place?.phone}</a></strong>
                                </td>
                            </tr>     
                        );
                      }

                        )}
                    </tbody>
                </table>
                {modalOpen && 
                  <ImageModal imageUrl={selectedImage} onClose={closeModal} />
                }
            </div>
          
        );
    }
    else 
  return (
    <div>
    {Array.isArray(data) ? (<table className="styled-table">
      <thead>
        <tr>
          <th>Кличка</th>
          <th>Количество по стране</th>
          {/* Добавьте другие заголовки, если необходимо */}
        </tr>
      </thead>
      <tbody>
        {data.map((item, index) => (
          <tr key={index}>
            <td>{item.pet_name}</td>
            <td>{item.count}</td>
            {/* Добавьте другие ячейки, соответствующие вашим данным */}
          </tr>
        ))}
      </tbody>
    </table>) : (
        <div>
         <div>
            <h4>Собаки</h4>
            <table className="styled-table">
            <thead>
            <tr>
                <th>Кличка</th>
                <th>Количество по стране</th>
                {/* Добавьте другие заголовки, если необходимо */}
            </tr>
            </thead>
            {data.dogs.length >0 ?<tbody>
            {data.dogs.map((item, index) => (
                <tr key={index}>
                <td>{item.pet_name}</td>
                <td>{item.count}</td>
                {/* Добавьте другие ячейки, соответствующие вашим данным */}
                </tr>
            ))}
            </tbody>:<tbody><tr><td colSpan={2}>Не найдено собак с такой кличкой</td></tr></tbody>}
        </table>
        </div>
        <hr></hr>
        
      <div>
        <h4>Кошки</h4>
        <table className="styled-table">
        <thead>
            <tr>
            <th>Кличка</th>
            <th>Количество по стране</th>
            {/* Добавьте другие заголовки, если необходимо */}
            </tr>
        </thead>
        {data.cats.length >0 ? 
        <tbody>
            {data.cats.map((item, index) => (
            <tr key={index}>
                <td>{item.pet_name}</td>
                <td>{item.count}</td>
                {/* Добавьте другие ячейки, соответствующие вашим данным */}
            </tr>
            ))}
        </tbody>
        :<tbody><tr><td colSpan={2}>Не найдено кошек с такой кличкой</td></tr></tbody>}
        </table>
        </div>

    </div>
      )}
      </div>
  );
};

export default Table;