import axios from 'axios';
import { useEffect, useState } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import Search from './components/Search/Search';
import BreedsReport from './components/BreedsReport/BreedsReport';
import NamesReport from './components/NamesReport/NamesReport';
import ArticleList from './components/Articles/ArticleList';
import ArticleDetail from './components/Articles/ArticleDetail';

import './App.css'

const App = () => {
    return (
     <Routes>
       <Route path='/' element={<Search />} />
       <Route path='/search' element={<Search />} />
       <Route path="/breeds_report" element={<BreedsReport />} />
       <Route path="/names_report" element={<NamesReport />} />
       <Route path="/articles_list" element={<ArticleList />} />
       <Route path="/article_detail/:id" element={<ArticleDetail />} />

   </Routes>
   );
 };
 export default App;