// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.modal {
    display: block; /* Change this to block */
    position: fixed;
    z-index: 1;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgb(0, 0, 0);
    background-color: rgba(0, 0, 0, 0.9);
  }
  
  .modal-content {
    margin: 5% auto;
    display: block;
    width: 80%;
    max-width: 700px;
  }
  
  .close {
    color: #ccc;
    float: right;
    font-size: 28px;
    font-weight: bold;
    cursor: pointer;
  }
  .modal-image{
    max-width: 300px;
  }`, "",{"version":3,"sources":["webpack://./src/components/ImageModal/ImageModal.css"],"names":[],"mappings":"AAAA;IACI,cAAc,EAAE,yBAAyB;IACzC,eAAe;IACf,UAAU;IACV,OAAO;IACP,MAAM;IACN,WAAW;IACX,YAAY;IACZ,cAAc;IACd,8BAA8B;IAC9B,oCAAoC;EACtC;;EAEA;IACE,eAAe;IACf,cAAc;IACd,UAAU;IACV,gBAAgB;EAClB;;EAEA;IACE,WAAW;IACX,YAAY;IACZ,eAAe;IACf,iBAAiB;IACjB,eAAe;EACjB;EACA;IACE,gBAAgB;EAClB","sourcesContent":[".modal {\n    display: block; /* Change this to block */\n    position: fixed;\n    z-index: 1;\n    left: 0;\n    top: 0;\n    width: 100%;\n    height: 100%;\n    overflow: auto;\n    background-color: rgb(0, 0, 0);\n    background-color: rgba(0, 0, 0, 0.9);\n  }\n  \n  .modal-content {\n    margin: 5% auto;\n    display: block;\n    width: 80%;\n    max-width: 700px;\n  }\n  \n  .close {\n    color: #ccc;\n    float: right;\n    font-size: 28px;\n    font-weight: bold;\n    cursor: pointer;\n  }\n  .modal-image{\n    max-width: 300px;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
