// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styled-table {
    background-color: #fff;
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
  }
  
  .styled-table th, .styled-table td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: left;
  }
  
  .styled-table th {
    background-color: #f2f2f2;
  }
  
  .styled-table tbody tr:hover {
    background-color: #f5f5f5;
  }
  .pet-icon{
    width: 70px;
    border-radius: 50px;
  }
  .inner-table tr td{
    border: none;
  }`, "",{"version":3,"sources":["webpack://./src/components/Table/Table.css"],"names":[],"mappings":"AAAA;IACI,sBAAsB;IACtB,WAAW;IACX,yBAAyB;IACzB,gBAAgB;EAClB;;EAEA;IACE,sBAAsB;IACtB,YAAY;IACZ,gBAAgB;EAClB;;EAEA;IACE,yBAAyB;EAC3B;;EAEA;IACE,yBAAyB;EAC3B;EACA;IACE,WAAW;IACX,mBAAmB;EACrB;EACA;IACE,YAAY;EACd","sourcesContent":[".styled-table {\n    background-color: #fff;\n    width: 100%;\n    border-collapse: collapse;\n    margin-top: 20px;\n  }\n  \n  .styled-table th, .styled-table td {\n    border: 1px solid #ddd;\n    padding: 8px;\n    text-align: left;\n  }\n  \n  .styled-table th {\n    background-color: #f2f2f2;\n  }\n  \n  .styled-table tbody tr:hover {\n    background-color: #f5f5f5;\n  }\n  .pet-icon{\n    width: 70px;\n    border-radius: 50px;\n  }\n  .inner-table tr td{\n    border: none;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
