import axios from 'axios';
import { useEffect, useState } from 'react';
import Table from '../Table/Table';
import Spinner from '../Spinner/Spinner';

import './BreedsReport.css'

function BreedsReport() {
    const backUrl = process.env.REACT_APP_BACK_URL;

    const [dataDogs, setDataDogs] = useState({});
    const [dataRegions, setDataRegions] = useState([]);
    const [openTables, setOpenTables] = useState({});
    
    const [loadingDogs, setLoadingDogs] = useState(false);
    const [loadingRegions, setLoadingRegions] = useState(false);

    
    const toggleTable = (regionId) => {
        setOpenTables((prevOpenTables) => ({
          ...prevOpenTables,
          [regionId]: !prevOpenTables[regionId],
        }));
      };
    
      const getReportByRegion = async (regionId) => {
        try {
          setLoadingDogs(true);
          const response = await axios.get(
            `${backUrl}/api/v1/pets/pet/statistics/by_breeds_regions_dogs?region=${regionId}`
          );
          setDataDogs((prevData) => ({ ...prevData, [regionId]: response.data }));
          toggleTable(regionId);
        } catch (error) {
          console.error('Error fetching data:', error);
        } finally {
          setLoadingDogs(false);
        }
      };

    useEffect(() => {
        const fetchData = async () => {
          try {
            // Выполняем запросы к API
            setLoadingRegions(true);
    
            const regionsResponse = await axios.get(`${backUrl}/api/v1/pets/region`);
    
            // Обновляем состояния данными
            setDataRegions(regionsResponse.data);
          } catch (error) {
            console.error('Error fetching data:', error);
          } finally {
            setLoadingRegions(false);
          }
        };
        fetchData();
      }, []);
      
    return (
        <div style={{backgroundColor: "#cae0de"}}>
            <div className="w3-top">
            <div className="w3-bar w3-card" style={{backgroundColor: "#015367"}}>
                <a href="/search" className="w3-bar-item w3-button w3-padding-large" style={{color:"#fff"}}>Поиск</a>
                <a href="/names_report" className="w3-bar-item w3-button w3-padding-large" style={{color:"#fff"}}>Топ кличек</a>
                <a href="/breeds_report" className="w3-bar-item w3-button w3-padding-large" style={{color:"#fff"}}>Топ пород</a>
                <a href="/articles_list" className="w3-bar-item w3-button w3-padding-large" style={{color:"#fff"}}>Полезное</a>
            </div>
            </div>            
            <div className="w3-content" style={{maxWidth:"2000px",marginTop:"46px"}}>
                <div className="w3-container w3-content w3-center w3-padding-16" style={{maxWidth:"800px"}} id="band">                
                    <div className="w3-row w3-padding-8" style={{backgroundColor:"#fff", padding:'12px'}}>
                        <h4>Рейтинг пород по регионам</h4>
                        <hr></hr>
                        {loadingRegions ? <Spinner />:<div>
                        {dataRegions.map((item, index) => (
                            <div key={item.id}>
                               <h5
                                onClick={() => getReportByRegion(item.id)}
                                style={{
                                    textAlign: 'left',
                                    cursor: 'pointer',
                                    backgroundColor: index % 2 === 0 ? '#eee' : 'transparent',
                                }}
                                >
                                {item.name_ru}
                                </h5>
                                {openTables[item.id] && loadingDogs && <Spinner />}
                                {openTables[item.id] && dataDogs[item.id] && <Table data={dataDogs[item.id]} by_breeds="true" />}
                            </div>
                        ))}
                        </div>}
                    </div>
                    <div>
                    <hr></hr>
                    <p className="w3-container">Данные взяты из публичного реестра домашних животных Казахстана <a target="_blank" href="https://tanba.kezekte.kz/ru/reestr-tanba-public/animal/list">tanba.kezekte.kz</a></p>
                    
                </div>
                </div>
            </div>
            </div>
    );
}

export default BreedsReport;
